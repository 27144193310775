<template>
  <div class="head">
    <div class="top_logo">
        <router-link :to="{path:'/index'}" class="top_router">
        <div class="fanghui_box" v-if="isMobile&&$route.path=='/my'">
            <router-link class="router" to="/index">
            <i class="el-icon-arrow-left"></i>
            </router-link>
        </div>
        
        <img src="@/assets/images/yeschat.png" class="logo_img" />
        <div v-if="$route.path=='/my'&&!isMobile">| 个人中心</div>
        <!-- <div v-if="$route.path=='/mychat'&&!isMobile">| 历史对话</div> -->
        </router-link>
        <div class="navigations" v-if="$route.path=='/index'||$route.path=='/chat'||$route.path=='/appchat'">
            <div class="navigation" :class="{active:navigationActive==index}" @click="navigationClick(index,item.title)" v-for="(item,index) in navigationList" :key="index">
                <i class="icon" :class="item.icon"></i>
                <div>{{item.title}}</div>
            </div>
        </div>
        <div class="use_box">
            <div class="box name_box">
                <!-- v-if="account.account_type!='免费试用'" -->
                <div class="icon_box" v-if="$route.path!='/my'||!isMobile">
                    <el-popover
                        placement="top-start"
                        width="300"
                        top="55"
                        :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
                        @show="showPopover"
                        trigger="click">
                        <!-- <div v-for="(item, index) in GetinfoList.msg_list" :key="index" class="timestamp_box">{{item.timestamp|timestampFile}} {{item.content}}</div> -->
                        <div v-if="GetinfoList.msg_list && GetinfoList.msg_list.length" >
                            <div v-for="(item, index) in GetinfoList.msg_list" :key="index" >
                                
                                <!-- {{item.timestamp|timestampFile}}  -->
                                <div class="timestamp-box">{{item.timestamp|timestampFile}}</div>
                                <div class="customMavon"> 

                                    <mavon-editor  :boxShadow='false' :value="item.content" :subfield="prop.subfield" :defaultOpen="prop.defaultOpen" :toolbarsFlag="prop.toolbarsFlag" :editable="prop.editable"></mavon-editor>
                                </div>
                               
                            </div>
                        </div>
                        <div v-else>
                            <span>无消息</span>
                        </div>
                       
                        <i class="icon el-icon-bell" slot="reference"><span class="span" v-if="msgLength>0">{{msgLength}}</span></i>
                    </el-popover>
                    
                </div>
                <el-tooltip popper-class="account_div" effect="light"  placement="bottom-start" v-if="account.account_type!='免费试用'&&!isMobile">
                    <div slot="content">
                        <div class="account_box">我的套餐：{{account.account_type}} <span class="lxkf" @click="toMy()">升级套餐</span></div>
                        <div class="account_box">剩余次数：{{account.remain_requests==-1?'无限':account.remain_requests}}次
                            <!-- <i @click="orefresh()" class="el-icon-refresh-right"></i> -->
                        </div>
                        <div class="account_box">积分：{{account.points}}<el-tooltip placement="bottom">
                                <div slot="content" class="prompt">积分可用于兑换使用次数和时长，敬请期待
                                </div>
                                <i class="el-icon-warning-outline"></i>
                            </el-tooltip></div>
                        <div class="account_box">回复长度限制：{{account.maxlen==-1?'无限制':account.maxlen+"字"}}</div>
                        <div class="account_box">连续多轮对话：{{account.multi_turn==0?'不支持':"支持"}}
                             <el-tooltip placement="bottom">
                                <div slot="content" class="prompt">在多轮对话状态下，AI会记忆历史对话，因此用户可以针对上一轮AI的回复展开追问。例如可以追问“继续写”、“总结下刚才的对话”、“把刚才你说的内容转成英文”等。
                                    <span class="multichatexample" @click="dialogexample=true">点击查看多轮对话示例</span>
                                    </div>
                                <i class="el-icon-warning-outline" v-if="account.multi_turn!=0"></i>
                            </el-tooltip>
                        </div>
                        <div class="account_box">到期时间：{{account.expire_date==-1?"-":account.expire_date}}</div>
                        <div class="account_box2 account_box" v-if="account.human_support!=0">有问题？<span class="lxkf" @click="dialogD=true">联系专属客服</span></div>
                    </div>
                    <!-- <img  v-if="!isMobile" class="img" style="display: block" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" src="@/assets/images/huiyuan.svg"  /> -->
                    <!-- <img class="img" style="display: block" src="@/assets/images/huiyuan.svg"  /> -->
                    <img   class="img" style="display: block" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" src="@/assets/images/huiyuan.svg"  />

                </el-tooltip>
            
            <span v-if="!isMobile">你好！{{account.username}}</span></div>
            <div class="box" v-if="account.username">
                <el-dropdown trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                        <div class="name">{{account.username['0']}}</div><i v-if="!isMobile" class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="1">个人中心</el-dropdown-item>
                      <el-dropdown-item command="2">历史对话</el-dropdown-item>
                      <el-dropdown-item command="3">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>
    <router-view :navigationActive="navigationActive" />
    
  <el-dialog :close-on-click-modal="false" title="扫码添加专属客服微信" :width="isMobile?'90%':'402px'" :visible.sync="dialogD">
        <div class="dialog_box" style="text-align: center;">
            <img src="@/assets/images/new/kf.jpg" class="kf_img" />
        </div>
    </el-dialog>

  <el-dialog :close-on-click-modal="true" title="多轮对话示例" :width="isMobile?'90%':'700px'" :visible.sync="dialogexample">
        <div class="demo-image__lazy" v-if="!isMobile">
            <el-image  v-for="url in urls" :key="url" :src="url" lazy></el-image>
        </div>
        <div class="demo-image__lazy" v-else>
            <el-image v-for="mobile_url in mobile_urls" :key="mobile_url" :src="mobile_url" lazy ></el-image>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { payAccountcheck,logout,boardGetinfo } from "@/api/user";
import { mavonEditor } from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
// import Mock from 'mockjs';
// import axios from "axios";
import MarkdownIt from 'markdown-it';
const md = new MarkdownIt();
// 使用Mock.js生成假数据
// Mock.mock('/api/user/boardGetinfo', 'get', {
//   "code": 0,
//   "result": {
//     "msg_list|5-10": [{
//       "content": " 你好啊，内容转成英文”等。 [点击查看多轮对话示例](http://t.wowchat.cn/img/example1.04106d78.png)",
//       "timestamp": "@date('yyyy-MM-dd HH:mm:ss')"
//     }],
//     "push_list|1-3": [{
//       "content": " 你好啊 内容转成英文”等。 [点击查看多轮对话示例](http://t.wowchat.cn/img/example1.04106d78.png)",
//       "timestamp": "@date('yyyy-MM-dd HH:mm:ss')"
//     }]
//   }
// });


export default {
    components:{// 在页面中注册组件
        mavonEditor 
    },
  data() {
    return {
        dialogD:false,
        dialogexample:false,
         urls: [
            require("@/assets/images/example1.png"),
            require("@/assets/images/example2.png"),
            require("@/assets/images/example3.png"),
            require("@/assets/images/example4.png"),
        
        ],
        mobile_urls: [
            require("@/assets/images/me1.jpg"),
            require("@/assets/images/me2.jpg"),
            require("@/assets/images/me3.jpg"),
            require("@/assets/images/me4.jpg"),

        ],
      account:{},
      timeoutId: null,
      fetched: false,
     
      isMobile:"",
    
      GetinfoList:{
        "msg_list":[]
      },
      msgLength:0,
      isNub:0,

      navigationList:[{
        "title":"找点灵感",
        "icon":"el-icon-magic-stick"
      },{
        "title":"快速出稿",
        "icon":"el-icon-document"
      },{
        "title":"稿件润色",
        "icon":"el-icon-edit-outline"
      },{
        "title":"定位Bug",
        "icon":"el-icon-dwbug"
      }],
      
    };
  },
  filters:{
    timestampFile(value){
        var thisTime=new Date().getTime();
        var nub=Math.floor(thisTime-(value*1000))/1000;
        if(nub>0&&nub<59){
            nub=Math.floor(nub)+"秒前:"
        }else if(nub>60&&nub<3599){
            nub=Math.floor(nub/60)+"分钟前:"
        }else if(nub>3600&&nub<86400){
            nub=Math.floor(nub/60/60)+"小时前:"
        }else{
            nub=Math.floor(nub/60/60/24)+"天前:"
        }
        return nub;
    }
  },
  mounted() {
    var that = this;
    that.getPayAccountcheck();
    that.getboardGetinfo();
    // that.getmock();
    that.isMobile=navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  },

computed: {
    prop () {
      let data = {
        subfield: false,// 单双栏模式
        defaultOpen: 'preview',//edit： 默认展示编辑区域 ， preview： 默认展示预览区域 
        editable: false,    // 是否允许编辑
        toolbarsFlag: false,
        scrollStyle: false
      }
      return data
    },
    navigationActive() {
        return this.$route.query.navigationActive||0
    }
  },
  methods: {
    // //定时刷新页面
    // getRemain_requests(){
    //     var that = this;
    //     clearTimeout(that.timeoutId);
    //     that.timeoutId = setTimeout(that.getPayAccountcheck, 2000);
    // },
    //导航点击事件
    navigationClick(index,title){
        var that = this;
        if(index==2||index==3){
            that.$router.push({
                path: "/appchat",query:{appTitle:title,navigationActive: index}
            });
        }else{
            that.$router.push({
                path: "/index",query:{navigationActive: index}
            });
            
        }
    },
    //显示消息
    showPopover(){
        var that = this;
        // that.GetinfoList.msg_list.push({content: '因此用户可以针对上一轮AI的回复展开追问。例如可以追问“继续写”、“总结下刚才的对话”、“把刚才你说的内容转成英文”等。 [点击查看多轮对话示例](http://t.wowchat.cn/img/example1.04106d78.png)'});
        // that.GetinfoList.msg_list.push({content: '你好'});
        // that.GetinfoList.msg_list.push({content: '干嘛'});
        localStorage.setItem("msg_list",that.GetinfoList.msg_list.length)
        var length=localStorage.getItem("msg_list");
        that.msgLength=that.GetinfoList.msg_list.length-(length||0);
        if(that.msgLength>9){
            that.msgLength=9
        }
    },
// 假数据测试
//     getmock(){
//         var that = this;

//         axios.get('/api/user/boardGetinfo').then(res => {
//             // console.log(res.data.result)
//         that.GetinfoList=res.data.result;
//         console.log(that.GetinfoList)
//         var length=localStorage.getItem("msg_list");
//         console.log(length)
//         that.msgLength=that.GetinfoList.msg_list.length-(length||0);
//         if(that.msgLength>9){
//             that.msgLength=9
//         }
//         var push_list=localStorage.getItem("push_list")?localStorage.getItem("push_list").split(","):"";
//         that.GetinfoList.push_list.forEach(elementA => {
//             // debugger
//             // console.log('elementA11111:',elementA)
//             if((!(push_list.indexOf(elementA.timestamp+"")>-1))&&that.isNub==0){
//                 that.isNub++;
//                 that.$alert( md.render(elementA.content), '系统提示', {
//                     confirmButtonText: '确定',
//                     showClose:false,
//                     dangerouslyUseHTMLString: true, // 允许使用HTML字符串
//                     callback: action => {
//                         push_list+=elementA.timestamp+","
//                         localStorage.setItem("push_list",push_list)
//                     }
//                 });
//             }
//         });
                
// })

//     },
    //获取用户日志
    getboardGetinfo() {
        var that = this;
  
        boardGetinfo().then((res) => {
            if(res.code==0){
               
                that.GetinfoList=res.result;
               
                var length=localStorage.getItem("msg_list");
                that.msgLength=that.GetinfoList.msg_list.length-(length||0);
                if(that.msgLength>9){
                    that.msgLength=9
                }
                var push_list=localStorage.getItem("push_list")?localStorage.getItem("push_list").split(","):"";
                that.GetinfoList.push_list.forEach(elementA => {
                    // debugger
                   
                    if((!(push_list.indexOf(elementA.timestamp+"")>-1))&&that.isNub==0){
                        that.isNub++;
                        that.$alert( md.render(elementA.content), '系统提示', {
                            confirmButtonText: '确定',
                            showClose:false,
                            dangerouslyUseHTMLString: true, // 允许使用HTML字符串
                            callback: action => {
                                push_list+=elementA.timestamp+","
                                localStorage.setItem("push_list",push_list)
                            }
                        });
                    }
                });
                
            }
        });
    },
    toMy(){
        var that = this;
        that.$router.push({ path: "/my" });
    },
    //  toSaveChat(){
    //     var that = this;
    //     that.$router.push({ path: "/mychat" });
    // },
    getPayAccountcheck(){
        var that = this;
        payAccountcheck().then((res) => {
            if(res.code==0){
                that.account=res.result;
                that.fetched=true;
                // this.$forceUpdate();
                // console.log("剩余次数：",that.account.remain_requests)
            }
        });
    },
    orefresh() {
      var that = this;
      
        that.getPayAccountcheck()
      },

    handleMouseEnter() {
    var that = this;
      if(!that.fetched){
        that.getPayAccountcheck()
      that.fetched = true}
    },
   
    handleMouseLeave() {
    var that = this;
      that.fetched = false
    },
    handleCommand(command) {
      var that = this;
      if (command == 1) {
        that.$router.push({ path: "/my" });
      } else if(command ==2){
        that.$router.push({ path: "/mychat" });
      }
      else if (command == 3) {
        if(that.isMobile){
            logout().then((res) => {
                if(res.code==0){
                    that.$router.push({ path: "/login" });
                }
            });
        }else{
            that
            .$confirm("确定要退出登录吗", "提示", {
                closeOnClickModal: false,
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(() => {
                logout().then((res) => {
                    if(res.code==0){
                        that.$router.push({ path: "/login" });
                    }
                });
                
            })
            .catch(() => {});
          }
      }
    },
  },

    // watch:{
    // account(newValue, oldValue){
    //     var that = this;
    //     that.getRemain_requests = that.account.remain_requests;
    //     that.$forceUpdate();
    // }

    // },
};
</script>
<style lang="less" scoped>
 .prompt {
  // 必须设置宽度
  width: 270px;
  word-break: break-word;
  white-space: pre-line;
  clear: both;
    // text-align: center;
    }
    .multichatexample{
        color: #409EFF;
        text-decoration: underline;
        cursor: pointer;
    }
    .el-image{
        display: block;
        margin-bottom: 0;
    }
    .el-icon-refresh-right{
        cursor: pointer;
    }


    // >>>.v-show-content{
    //     background-color: #d81e06 ;
    // }

.customMavon /deep/ .v-note-wrapper{
    display: block !important;
    position: static !important;
    min-height: auto !important;
    min-width: auto;
    color: #606266;
    // padding:5px 0,0,0;
    min-height: auto;
    background-color: #ffffff !important;
    font-size: 14px;

}
.customMavon /deep/ .v-note-wrapper .v-note-panel .v-note-show .v-show-content, .v-note-wrapper .v-note-panel .v-note-show .v-show-content-html{
    padding:5px 0 0 0;
    background-color: #ffffff!important;
}
// .el-popoer{
//     height: 400px;
//     overflow: auto;
// }

//     .mavon-editor .content-box .editor-preview-side {
//   box-shadow: none;
//   color: #eb1a1a;
// }


</style>
